import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./utils/i18n";
import reportWebVitals from "./utils/reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <App />
    </React.Suspense>
  </React.StrictMode>
);

reportWebVitals();
