import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDomainConfig } from "../../utils/configuration";
import { GetLoginResult } from "../../utils/types";

const LoginForm = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("isLoading has changed:", isLoading);
  }, [isLoading]); // Add a useEffect to log when isLoading changes

  const handleLogin = async () => {
    const callLogin = async () => {
      const domainConfig = await getDomainConfig();

      try {
        setTimeout(() => {
          setIsLoading(true);
        }, 0);

        const config = {
          method: "post",
          url: `${domainConfig.backendBaseUrl}login`,
          headers: { "Content-Type": "application/json" },
          data: {
            username: username,
            password: password
          }
        };
        const response = await axios(config);
        if (response.status === 200) {
          const getLoginResult: GetLoginResult = response.data;
          localStorage.setItem("token", getLoginResult.token);
        }

        const isAdminToken = await callCheckAdminToken();
        if (isAdminToken) {
          navigate("/guestmanagement");
        } else {
          localStorage.removeItem("token");
          window.location.reload();
        }
      } catch (error: any) {
        console.log(error);
        alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
      } finally {
        setIsLoading(false); // Disable loading when done
      }
    };

    await callLogin();
  };

  const callCheckAdminToken = async (): Promise<boolean> => {
    const domainConfig = await getDomainConfig();
    let isAdminToken: boolean = false;

    try {
      setTimeout(() => {
        setIsLoading(true);
      }, 0);

      const config = {
        method: "post",
        url: `${domainConfig.backendBaseUrl}checkAdminToken`,
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem("token")! }
      };
      const response = await axios(config);
      if (response.status === 200) {
        isAdminToken = response.data.isAdmin;
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    } finally {
      setIsLoading(false); // Disable loading when done
    }

    setIsLoading(false); // Disable loading when done
    return isAdminToken;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: "300px", // Adjust as needed
        margin: "0 auto", // Horizontally center
        border: "1px solid #ccc",
        padding: "20px",
        backgroundColor: "#f9f9f9"
      }}
    >
      <div>
        <h2>Admin Login</h2>
        <div style={{ display: "flex", flexDirection: "column", margin: 20 }}>
          <label htmlFor="username">Username:</label>
          <input type="text" id="username" value={username} onChange={e => setUsername(e.target.value)} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", margin: 20 }}>
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <button
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            margin: 20
          }}
          onClick={handleLogin}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
