import { DownloadOutlined, EditOutlined, HomeFilled } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import "./overviewBookingStyle.css";

const OverviewBooking = (params: any) => {
  const { t } = useTranslation();

  // mock data
  const resources = {
    title: "Guest title",
    firstName: "Guest first name",
    lastName: "Guest last name",
    address: "Guest adress",
    checkIn: "Guest check-in date",
    checkOut: "Guest check-out date",
    price: "Guest booking price",
    username: "Guest username",
    password: "Guest password",
    codeStatus: "Guest code status"
  };

  return (
    <div className="wrapper">
      <div className="header-overview">
        <h3>{t("overview.h3-title")}</h3>
      </div>
      <h4>{t("overview.h4-title")}</h4>
      <div className="form-wrapper">
        <span className="guest-data-inline">
          <label className="guest-data-label">
            <p>{t("overview.title")}</p>
            <p className="guest-data">{resources.title}</p>
          </label>
          <label>
            <p>{t("overview.f-name")}</p>
            <p className="guest-data">{resources.firstName}</p>
          </label>
          <label>
            <p>{t("overview.l-name")}</p>
            <p className="guest-data">{resources.lastName}</p>
          </label>
        </span>
        <span className="guest-data-inline">
          <label className="address-long-label">
            <p className="address-long">{t("overview.address")}</p>
            <p className="guest-data-address-long">{resources.address}</p>
          </label>
        </span>
        <span className="guest-data-inline">
          <label>
            <p>{t("overview.check-in")}</p>
            <p className="guest-data">{resources.checkIn}</p>
          </label>
          <label>
            <p>{t("overview.check-out")}</p>
            <p className="guest-data">{resources.checkOut}</p>
          </label>
          <label>
            <p>{t("overview.price")}</p>
            <p className="guest-data">{resources.price}</p>
          </label>
        </span>
        <span className="guest-data-inline">
          <label>
            <p>{t("overview.username")}</p>
            <p className="guest-data">{resources.username}</p>
          </label>
          <label>
            <p>{t("overview.password")}</p>
            <p className="guest-data">{resources.password}</p>
          </label>
          <label>
            <p>{t("overview.code-stat")}</p>
            <p className="guest-data">{resources.codeStatus}</p>
          </label>
        </span>
      </div>

      <div className="button-wrapper">
        <button className="edit">
          <EditOutlined className="button-icon-short" />
          {t("button.edit")}
        </button>
        <button className="download">
          <DownloadOutlined className="button-icon-short" />
          {t("button.download")}
        </button>
        <button className="apartmentControl">
          <HomeFilled className="button-icon" />
          {t("button.apartment-control")}
        </button>
      </div>
    </div>
  );
};

export default OverviewBooking;
