import _ from "lodash";

export const DEFAULT_DATE_TIME_PATTERN =  'en-CA' // YYYY-MM-DD, HH:MM

export function formatDateTime(date: Date, options: Intl.DateTimeFormatOptions = {}, localeDatePattern?: string): string {
  const _options: Intl.DateTimeFormatOptions = _.assign(
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    },
    options
  );
  const _localeDatePattern = localeDatePattern || getDefaultDateTimePattern();
  return date.toLocaleString(_localeDatePattern, _options).replace("a.m", "AM").replace("p.m", "PM");
}

export function formatDateTimestamp(second: number, options: Intl.DateTimeFormatOptions = {}, localeDatePattern?: string): string {
  const _date = new Date(second * 1000);
  return formatDateTime(_date, options, localeDatePattern);
}

/*
  Default = en-CA
 */
export function getDefaultDateTimePattern(): string {
  return DEFAULT_DATE_TIME_PATTERN;
}
