import { doc, getDoc } from "@firebase/firestore";
import axios from "axios";
import { db } from "./firebase";
import { Domain, PropertyConfig } from "./types";

// FIXME we should manage this url finding!
const getDomainConfig = async (): Promise<Domain> => {
  const domainConfig: Domain = {};
  const docRef = doc(db, "configurations", "DEFAULT");
  const docSnap = await getDoc(docRef);
  const defaultConfig = docSnap.data();
  for (const property in defaultConfig) {
    domainConfig[property as keyof Domain] = defaultConfig[property];
  }
  console.debug("Set to domain config level");
  return domainConfig;
};

const getPropertyConfig = async (propertyId: string): Promise<PropertyConfig> => {
  const callGetPropertyConfigApi = async (): Promise<any> => {
    const domainConfig = await getDomainConfig();

    try {
      let token = localStorage.getItem("token");
      if (token == null) token = "no-token-found";
      const config = {
        method: "get",
        url: `${domainConfig.backendBaseUrl}getProperty?propertyId=${propertyId}`,
        headers: { "Content-Type": "application/json", Authorization: token }
      };
      const response = await axios(config);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
      return null;
    }
  };

  return await callGetPropertyConfigApi();
};

const getAllProperties = async (): Promise<any> => {
  const callGetAllPropertiesApi = async (): Promise<any> => {
    const domainConfig = await getDomainConfig();

    try {
      let token = localStorage.getItem("token");
      if (token == null) token = "no-token-found";
      const config = {
        method: "get",
        url: `${domainConfig.backendBaseUrl}getAllProperties`,
        headers: { "Content-Type": "application/json", Authorization: token }
      };
      const response = await axios(config);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
      return null;
    }
  };

  return await callGetAllPropertiesApi();
};

export { getDomainConfig, getPropertyConfig, getAllProperties };
