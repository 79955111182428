import React from "react";
import { useTranslation } from "react-i18next";
import "./HomeStyle.css";

const Home = ({ params }: any) => {
  const { t } = useTranslation();

  return (
    <div className="home-wrapper">
      <img className="logo" src="https://media.xmlcal.com/pic/p0011/1030/16.png" alt="NOTHING" />
      <h2>{t("home.h2-title")}</h2>
      <p>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        {t("home.p-first")} "{t("home.link-registration")}" {t("home.p-second")}
      </p>
    </div>
  );
};

export default Home;
