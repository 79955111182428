import { GoogleOutlined } from "@ant-design/icons";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import GuestManagement from "../GuestManagement/GuestManagement";
import "./LoginStyle.css";

function Login() {
  const auth = getAuth();
  const [user] = useAuthState(auth);

  const googleProvider = new GoogleAuthProvider();
  const GoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log(result.user);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="login-wrapper">
      {!user && (
        <div className="login-div">
          <img
            className="logo-login"
            src="https://storage.googleapis.com/jobelhome-production.appspot.com//media/thumbnail.png"
            alt="NOTHING"
          />
          <h2 className="login-h2">Please log in to access the page.</h2>

          <div>
            <button onClick={GoogleLogin} className="sign-in-button">
              <GoogleOutlined className="button-icon" />
              Sign in with Google
            </button>
          </div>
        </div>
      )}
      {user && (
        <>
          <GuestManagement />
        </>
      )}
    </div>
  );
}

export default Login;
