import { DownOutlined, FileTextOutlined } from "@ant-design/icons";
import axios from "axios";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import React, { useEffect, useRef, useState } from "react";
import useCollapse from "react-collapsed";
import { useTranslation } from "react-i18next";
import {
  AccessCodeResponseDto,
  LockType,
  LoxoneAccessCode,
  StaticCodeAccessCode,
  TimestampTransmitted,
  TTLockAccessCode
} from "../../utils/types";
import "./RegformStyle.css";
import { isDateOfBirthValid, isEmailValid } from "../../utils/validation";
import _ from "lodash";
import { formatDateTimestamp } from "../../utils/utils";

const CollapsibleGuests = ({
  i,
  j,
  bookingItems,
  setBookingItems,
  isRequiredGuestProperties
}: {
  i: any;
  j: any;
  bookingItems: any;
  setBookingItems: any;
  isRequiredGuestProperties: IsRequiredGuestProperty;
}) => {
  const addressEl = useRef<HTMLInputElement>(null);
  const postalCodeEl = useRef<HTMLInputElement>(null);
  const cityEl = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "" });

  const guest = bookingItems[i].guests[j];
  const isFamilyMember = bookingItems[i].guests[j].isFamilyMember;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const changeFormFieldValue = (e: any, source: string) => {
    const _value = e.target.value;
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[j][source] = _value;
    setBookingItems(_bookingItemsCopy);
    if ("address" === source || "postalCode" === source || "city" === source) setIsFamilyFieldsValueForAllGuests();
  };

  const changeIsFamilyFieldValue = (e: any, source: string) => {
    const _isFamilyMember = e.target.checked;
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[j][source] = _isFamilyMember;
    addressEl?.current && setInputValidationClass(addressEl.current, true);
    postalCodeEl?.current && setInputValidationClass(postalCodeEl.current, true);
    cityEl?.current && setInputValidationClass(cityEl.current, true);
    if (_isFamilyMember) setIsFamilyFieldsValue(j);
    setBookingItems(_bookingItemsCopy);
  };

  const setIsFamilyFieldsValue = (currentGuestIndex: string) => {
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[currentGuestIndex]["address"] = _bookingItemsCopy[i].guests[0]["address"];
    _bookingItemsCopy[i].guests[currentGuestIndex]["postalCode"] = _bookingItemsCopy[i].guests[0]["postalCode"];
    _bookingItemsCopy[i].guests[currentGuestIndex]["city"] = _bookingItemsCopy[i].guests[0]["city"];
    setBookingItems(_bookingItemsCopy);
  };

  const setIsFamilyFieldsValueForAllGuests = () => {
    _.forEach(bookingItems[i].guests, (guest, index) => {
      if (guest.isFamilyMember) setIsFamilyFieldsValue(index);
    });
  };

  const validateForm = (e: any, source: string) => {
    const _value = e.target.value;
    setRequiredInputValidationClass(e);
    if (_value && "email" === source) setValidationClassForEmail(e);
    if (_value && "dateofbirth" === source) setValidationClassForDateOfBirth(e);
  };

  const setInputValidationClass = (e: Element, isValid: boolean) => {
    if (isValid) e.classList.remove("propertyForm-invalid");
    else e.classList.add("propertyForm-invalid");
  };

  const setRequiredInputValidationClass = (e: any) => {
    const _input = e.target;
    const _isValid = !(_input.required && _input.value.trim() === "");
    setInputValidationClass(_input, _isValid);
  };

  const setValidationClassForEmail = (e: any) => {
    const _input = e.target;
    const _isValid = isEmailValid(_input.value);
    setInputValidationClass(_input, _isValid);
  };

  const setValidationClassForDateOfBirth = (e: any) => {
    const _input = e.target;
    const _isValid = isDateOfBirthValid(_input.value);
    setInputValidationClass(_input, _isValid);
  };

  return (
    <div className="collapsible">
      <div className="form-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "" : <FileTextOutlined style={{ fontSize: 30 }} />}
        {isExpanded ? <DownOutlined className="arrow" /> : ""}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <form id="guest-reg-form" className="regformWrapper">
            <span className="inline">
              <label>
                <p>
                  {t("registration.fname")}
                  {isRequiredGuestProperties.firstName && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredGuestProperties.firstName}
                  placeholder={t("registration.fname")}
                  value={guest.firstName}
                  onChange={e => changeFormFieldValue(e, "firstName")}
                  onBlur={e => validateForm(e, "firstName")}
                />
              </label>
              <label>
                <p>
                  {t("registration.lname")}
                  {isRequiredGuestProperties.lastName && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredGuestProperties.lastName}
                  placeholder={t("registration.lname")}
                  value={guest.lastName}
                  onChange={e => changeFormFieldValue(e, "lastName")}
                  onBlur={e => validateForm(e, "lastName")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.dateofbirth")}
                  {isRequiredGuestProperties.dateOfBirth && "*"}
                </p>
                <input
                  className="inputField"
                  type="date"
                  required={isRequiredGuestProperties.dateOfBirth}
                  placeholder={t("registration.dateofbirth")}
                  value={guest.dateOfBirth}
                  onChange={e => changeFormFieldValue(e, "dateOfBirth")}
                  onBlur={e => validateForm(e, "dateOfBirth")}
                />
              </label>
              <label>
                <p>
                  {t("registration.placeofbirth")}
                  {isRequiredGuestProperties.placeOfBirth && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.placeOfBirth}
                  required={isRequiredGuestProperties.placeOfBirth}
                  onChange={e => changeFormFieldValue(e, "placeOfBirth")}
                  onBlur={e => validateForm(e, "placeOfBirth")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.gender")}
                  {isRequiredGuestProperties.gender && "*"}
                </p>
                <select
                  className="dropDown"
                  name="gender"
                  value={guest.gender}
                  required={isRequiredGuestProperties.gender}
                  onChange={e => changeFormFieldValue(e, "gender")}
                  onBlur={e => validateForm(e, "gender")}
                >
                  <option value="">{t("registration.choosegender")}</option>
                  <option value="Male">{t("registration.male")}</option>
                  <option value="Female">{t("registration.female")}</option>
                  <option value="Other">{t("registration.other")}</option>
                </select>
              </label>

              <label>
                <p>
                  {t("registration.nationality")}
                  {isRequiredGuestProperties.nationality && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.nationality}
                  required={isRequiredGuestProperties.nationality}
                  onChange={e => changeFormFieldValue(e, "nationality")}
                  onBlur={e => validateForm(e, "nationality")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            {j !== 0 ? (
              <label>
                <p>{t("registration.familyMember")}</p>
                <div className="inputField-familyMember-wrapper">
                  <input
                    type="checkbox"
                    id="familyMember"
                    name="familyMember"
                    onChange={e => changeIsFamilyFieldValue(e, "isFamilyMember")}
                    checked={guest.isFamilyMember}
                  ></input>
                </div>
              </label>
            ) : (
              ""
            )}
            <span className="inline">
              <label>
                <p>
                  {t("registration.street")}
                  {isRequiredGuestProperties.address && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.street")}
                  value={guest.address}
                  required={isRequiredGuestProperties.address}
                  ref={addressEl}
                  onChange={e => changeFormFieldValue(e, "address")}
                  onBlur={e => validateForm(e, "address")}
                />
              </label>
              <label>
                <p>
                  {t("registration.postalcode")}
                  {isRequiredGuestProperties.postalCode && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.postalcode")}
                  value={guest.postalCode}
                  required={isRequiredGuestProperties.postalCode}
                  ref={postalCodeEl}
                  onChange={e => changeFormFieldValue(e, "postalCode")}
                  onBlur={e => validateForm(e, "postalCode")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.city")}
                  {isRequiredGuestProperties.city && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.city")}
                  value={guest.city}
                  required={isRequiredGuestProperties.city}
                  ref={cityEl}
                  onChange={e => changeFormFieldValue(e, "city")}
                  onBlur={e => validateForm(e, "city")}
                />
              </label>
              <label>
                <p>
                  {t("registration.country")}
                  {isRequiredGuestProperties.country && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.country}
                  required={isRequiredGuestProperties.country}
                  onChange={e => changeFormFieldValue(e, "country")}
                  onBlur={e => validateForm(e, "country")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.id")}
                  {isRequiredGuestProperties.typeOfId && "*"}
                </p>
                <select
                  className="dropDown"
                  name="typeOfID"
                  value={guest.typeOfID}
                  required={isRequiredGuestProperties.typeOfId}
                  onChange={e => changeFormFieldValue(e, "typeOfID")}
                  onBlur={e => validateForm(e, "typeOfID")}
                >
                  <option value="">{t("registration.Def")}</option>
                  <option value="idNumber">{t("registration.idNumberOpt")}</option>
                  <option value="passport">{t("registration.passportOpt")}</option>
                  <option value="drivingLicense">{t("registration.drivingLicenseOpt")}</option>
                </select>
              </label>

              <label>
                <p>
                  {t("registration.passport")}
                  {isRequiredGuestProperties.idNumber && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  placeholder={t("registration.passport")}
                  value={guest.idNumber}
                  required={isRequiredGuestProperties.idNumber}
                  onChange={e => changeFormFieldValue(e, "idNumber")}
                  onBlur={e => validateForm(e, "idNumber")}
                />
              </label>
            </span>
            <label>
              <p>
                {t("registration.email")}
                {isRequiredGuestProperties.email && "*"}
              </p>
              <input
                className="inputField-email"
                type="email"
                placeholder={t("registration.email")}
                value={guest.email}
                required={isRequiredGuestProperties.email}
                onChange={e => changeFormFieldValue(e, "email")}
                onBlur={e => validateForm(e, "email")}
              />
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

const CollapsibleAccessCode = ({ bookingId, backendUrl }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accessData, setAccessData] = useState<AccessCodeResponseDto>();
  const [gateCode, setGateCode] = useState<StaticCodeAccessCode>();
  const { getToggleProps, isExpanded, setExpanded } = useCollapse();

  useEffect(() => {
    const fetchAccessCodeData = async () => {
      await callGetAccessCode();
    };

    fetchAccessCodeData();
  }, []);

  const handleClick = async () => {
    if (isExpanded) {
      setExpanded(false);
      return;
    }
    await callGetAccessCode();
    setGateCodeFromAllAccesses();
    setExpanded(prev => !prev);
  };

  const setGateCodeFromAllAccesses = () => {
    if (!accessData?.accessCodes) return;
    for (const [index, accessCode] of accessData.accessCodes.entries()) {
      const accessCodeInfo =
        accessCode?.type === LockType.STATIC_CODE ? (accessCode?.info as StaticCodeAccessCode) : undefined;
      if (accessCodeInfo?.accessCode) {
        setGateCode(accessCodeInfo);
        removeGateCodeInsideAccessCodes(index);
        break;
      }
    }
  };

  const removeGateCodeInsideAccessCodes = (index: number) => {
    if (!accessData) return;
    accessData.accessCodes.splice(index, 1);
    setAccessData(accessData);
  };

  const callGetAccessCode = async (): Promise<AccessCodeResponseDto | null> => {
    try {
      setTimeout(() => {
        setIsLoading(true);
      }, 1);

      const config = {
        method: "post",
        url: `${backendUrl}getAccessCode`,
        headers: { "Content-Type": "application/json" },
        data: {
          bookingId
        }
      };
      const response = await axios(config);
      const getAccessCodeResult: AccessCodeResponseDto = response.data;
      setAccessData(getAccessCodeResult);
      return getAccessCodeResult;
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    } finally {
      setIsLoading(false); // Disable loading when done
    }
    return null;
  };

  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const isAccessKeyActive = (
    validFrom: TimestampTransmitted | undefined,
    validUntil: TimestampTransmitted | undefined
  ): boolean => {
    if (accessData?.accessCodes?.length === 0) return false;
    if (!validFrom || !validUntil) return true;
    let _isActive = true;
    const _timeStampNow = Date.now();
    if (validFrom?._seconds && validFrom._seconds * 1000 > _timeStampNow) _isActive = false;
    if (validUntil?._seconds && validUntil._seconds * 1000 < _timeStampNow) _isActive = false;
    return _isActive;
  };

  const gateDate = (second: number): string => {
    return formatDateTimestamp(second);
  };

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps({ onClick: handleClick })}>
        {isExpanded ? "Hide Entry Code" : "Entry Code"}
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <p className="loading-text">Loading...</p>
        </div>
      )}
      <div className={`entry-code-collapse ${isExpanded ? "isExpanded" : ""}`}>
        <div className={"entry-code-section"}>
          <p className="dashboard-guest-access-title medium center">The access code for entering apartment</p>

          {gateCode && gateCode.accessCode && gateCode.name && (
            <GateEntryCodeData gateCode={gateCode.accessCode} name={gateCode.name} />
          )}

          <div className={"entry-code-lock-wrapper"}>
            {accessData?.accessCodes?.map(access => (
              <>
                {!access.type && <AccessErrorMessage errorMessage={access.error} />}

                {access.type === LockType.TTLOCK && (
                  <TTLockLockAccessData
                    accessInfoData={access.info}
                    error={access.error}
                    isEntryCodeActive={isAccessKeyActive(accessData?.validFrom, accessData?.validUntil)}
                  />
                )}

                {access.type === LockType.NONE && <NoneAccessData error={access.error} />}

                {access.type === LockType.STATIC_CODE && (
                  <StaticCodeAccessData accessInfoData={access.info} error={access.error} />
                )}

                {access.type === LockType.LOXONE && (
                  <LoxoneLockAccessData
                    accessInfoData={access.info}
                    error={access.error}
                    isEntryCodeActive={isAccessKeyActive(accessData?.validFrom, accessData?.validUntil)}
                    validFrom={accessData?.validFrom}
                    validUntil={accessData?.validUntil}
                  />
                )}
              </>
            ))}
          </div>
        </div>
      </div>

      <div className={`content dashboard-guest-checkin-checkout-time`}>
        <div className="dashboard-guest-access-title center medium" style={{ marginTop: "16px" }}>
          Active:&nbsp;
          {accessData?.validFrom ? gateDate(accessData?.validFrom._seconds) : ""}
          &nbsp;-&nbsp;
          {accessData?.validUntil ? gateDate(accessData?.validUntil._seconds) : ""}
        </div>
        <div className="dashboard-guest-access-title center medium" style={{ marginTop: "12px" }}>
          EARLY CHECK-IN / LATE CHECK-OUT
        </div>
        <div className="dashboard-guest-access-title center medium" style={{ marginTop: "8px" }}>
          Upon request. Please contact us.
        </div>
      </div>
    </div>
  );
};

const CollapsibleLoxoneCredentials = ({
  url,
  username,
  password
}: {
  url: string;
  username: string;
  password: string;
}) => {
  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "Hide Information" : ""}
        {isExpanded ? "" : "Control Apartment"}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <p className="dashboard-guest-loxone-data">Control your apartment heating, lighting and ...</p>
          <p className="dashboard-guest-loxone-data">
            Dashboard:{" "}
            <a href={url} target="_blank" rel="noreferrer">
              Application URL
            </a>
          </p>
          <div className="loxone-credentials-grid">
            <p className="dashboard-guest-loxone-data">Username: {username}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(username);
              }}
            >
              Copy
            </button>
            <p className="dashboard-guest-loxone-data">Password: {password}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(password);
              }}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoxoneLockAccessData = ({
  accessInfoData,
  error,
  isEntryCodeActive,
  validFrom,
  validUntil
}: {
  accessInfoData?: LoxoneAccessCode;
  error?: string;
  isEntryCodeActive: boolean;
  validFrom: TimestampTransmitted;
  validUntil: TimestampTransmitted;
}) => {
  const gateDate = (second: number): string => {
    return formatDateTimestamp(second)
  };

  return (
    <div className={`content`}>
      {!error && accessInfoData && (
        <>
          <div className="dashboard-guest-access-content">
            <p className="dashboard-guest-access-title">{accessInfoData.name} Entry Code:</p>
            <p className={`dashboard-guest-access-value ${!isEntryCodeActive && "inactive"}`}>
              {accessInfoData?.accessCode}
            </p>
            <ActivateBadge isEntryCodeActive={isEntryCodeActive} />
          </div>

          {!!validFrom && !!validUntil?._seconds && (
            <>
              <div className="dashboard-guest-access-title center medium" style={{ marginTop: "16px" }}>
                Active:&nbsp;
                {validFrom ? gateDate(validFrom._seconds) : ""}
                &nbsp;-&nbsp;
                {validUntil ? gateDate(validUntil._seconds) : ""}
              </div>
              <div className="dashboard-guest-access-title center medium" style={{ marginTop: "12px" }}>
                EARLY CHECK-IN / LATE CHECK-OUT
              </div>
              <div className="dashboard-guest-access-title center medium" style={{ marginTop: "8px" }}>
                Upon request. Please contact us.
              </div>
            </>
          )}

          {accessInfoData.propertyEnabledControl && (
            <>
              <CollapsibleLoxoneCredentials
                url={accessInfoData.loxoneGatewayUrl || ""}
                username={accessInfoData.userName || ""}
                password={accessInfoData.userPassword || ""}
              />
            </>
          )}
        </>
      )}
      <AccessErrorMessage errorMessage={error} />
    </div>
  );
};

const TTLockLockAccessData = ({
  accessInfoData,
  error,
  isEntryCodeActive
}: {
  accessInfoData?: TTLockAccessCode;
  isEntryCodeActive: boolean;
  error?: string;
}) => {
  return (
    <div className={`content`}>
      {!error && accessInfoData && (
        <>
          <div className="dashboard-guest-access-content">
            <p className="dashboard-guest-access-title">{accessInfoData.name} Entry Code:</p>
            <p className={`dashboard-guest-access-value ${!isEntryCodeActive && "inactive"}`}>
              {accessInfoData?.accessCode}
            </p>
            <ActivateBadge isEntryCodeActive={isEntryCodeActive} />
          </div>
        </>
      )}
      <AccessErrorMessage errorMessage={error} />
    </div>
  );
};

const StaticCodeAccessData = ({ accessInfoData, error }: { accessInfoData?: StaticCodeAccessCode; error?: string }) => {
  return (
    <div className={`content`}>
      {!error && accessInfoData && (
        <>
          <div className="dashboard-guest-access-content">
            <p className="dashboard-guest-access-title">{accessInfoData.name} Entry Code:</p>
            <p className={`dashboard-guest-access-value`}>{accessInfoData?.accessCode}</p>
          </div>
        </>
      )}
      <AccessErrorMessage errorMessage={error} />
    </div>
  );
};

const NoneAccessData = ({ error }: { error?: string }) => {
  if (!error) return null;

  return (
    <div className="content">
      <AccessErrorMessage errorMessage={error} />
    </div>
  );
};

const GateEntryCodeData = ({ gateCode, name }: { gateCode: string; name: string }) => {
  return (
    <>
      {gateCode && (
        <div className="dashboard-guest-access-content">
          <p className="dashboard-guest-access-title">{name} Entry Code:</p>
          <p className="dashboard-guest-access-value">{gateCode}</p>
        </div>
      )}
    </>
  );
};

const AccessErrorMessage = ({ errorMessage }: { errorMessage?: string }) => {
  return <>{errorMessage && <div className="dashboard-guest-access-value entry-code-warning">{errorMessage}</div>}</>;
};

const ActivateBadge = ({ isEntryCodeActive }: { isEntryCodeActive: boolean }) => {
  return (
    <p className={`dashboard-guest-access-valid-badge ${isEntryCodeActive ? "active" : "inactive"}`}>
      {isEntryCodeActive ? "Active" : "Inactive"}
    </p>
  );
};

export { CollapsibleGuests, CollapsibleAccessCode, CollapsibleLoxoneCredentials };

export interface IsRequiredGuestProperty {
  firstName: boolean;
  lastName: boolean;
  dateOfBirth: boolean;
  placeOfBirth: boolean;
  gender: boolean;
  nationality: boolean;
  address: boolean;
  postalCode: boolean;
  city: boolean;
  country: boolean;
  idNumber: boolean;
  email: boolean;
  typeOfId: boolean;
}
