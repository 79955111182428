import axios from "axios";
import LZString from "lz-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDomainConfig } from "../../utils/configuration";
import { BookingItemTransmitted, GetLoginResult } from "../../utils/types";
import "./DashboardStyle.css";
import ItemCard from "./ItemCard";

const getGuestToken = async () => {
  await handleLogin();
};

const Dashboard = () => {
  const { t } = useTranslation();
  const [bookingItems, setBookingItems] = useState<Array<BookingItemTransmitted>>([]);
  const [backendBaseUrl, setBackendBaseUrl] = useState<string>("");
  const [directionLinks, setDirectionLinks] = useState<{ [propertyId: string]: string }>({});

  useEffect(() => {
    const fetchTokenAndData = async () => {
      await getGuestToken();
    };

    fetchTokenAndData();
  }, []);

  const params: any = {};
  window.location.search
    .slice(1)
    .split("&")
    .forEach((pair: any) => {
      pair = pair.split("=");
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

  if (bookingItems.length === 0) {
    const decommpressedData = LZString.decompressFromEncodedURIComponent(params.data);
    const dashboardData = JSON.parse(decommpressedData);
    setBackendBaseUrl(dashboardData.backend);
    setBookingItems(dashboardData.items);
    setDirectionLinks(dashboardData.directionLinks);
    if (!dashboardData.backend) console.error("No backend url was specified in the url");
  }

  return (
    <div className="dashboard-wrapper">
      <h1 className="h1-success">{t("successpage.h1-success")}</h1>
      <div className="success-body">
        {bookingItems
          ? bookingItems.map((bookingItem, key) => (
              <ItemCard
                key={key}
                bookingItem={bookingItem}
                backendBaseUrl={backendBaseUrl}
                directionLink={directionLinks[bookingItem.propertyId]}
              />
            ))
          : ""}
      </div>
      <p className="main-info">{t("successpage.mainInfo2")}</p>
    </div>
  );
};

const handleLogin = async () => {
  const callLogin = async () => {
    const domainConfig = await getDomainConfig();

    try {
      const config = {
        method: "post",
        url: `${domainConfig.backendBaseUrl}login`,
        headers: { "Content-Type": "application/json" },
        data: {
          username: "guest",
          password: "guest"
        }
      };
      const response = await axios(config);
      if (response.status === 200) {
        const getLoginResult: GetLoginResult = response.data;
        localStorage.setItem("token", getLoginResult.token);
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    }
  };

  await callLogin();
};

export default Dashboard;
