import React from "react";
import "./JhErrorPage.css";

const JhErrorPage = () => {
  const params: any = {};
  window.location.search
    .slice(1)
    .split("&")
    .forEach((pair: any) => {
      pair = pair.split("=");
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

  let paramText = params.text;
  const paramRef = params.ref;
  if (!paramText) paramText = "Missing text parameter";
  const bookingId = params.bookingId;
  return (
    <div className="regfail-wrapper">
      <img className="logo" src="https://media.xmlcal.com/pic/p0011/1030/16.png" alt="NOTHING" />
      <div className="failbox">
        <h2 className="regfail-text">
          <p>
            Hi <br /> {paramText},<br />
            Your request is not possible. <br />
            {/* use tile as parameter having a generics headline */}
            {/* Please contact the support. */}
          </p>
        </h2>
        <p className="ref-text">
          {paramRef} <br />
          {bookingId}
        </p>
      </div>
    </div>
  );
};

export default JhErrorPage;
