import axios from "axios";
import { TenantCustomizationInfo } from "../utils/types";

export async function getTenantCustomizationInfoByTechId(
  backendBaseUrl: string,
  propertyTechId: string
): Promise<TenantCustomizationInfo | undefined> {
  try {
    const config = {
      method: "get",
      url: `${backendBaseUrl}getTenantCustomizationInfoByTechId/${propertyTechId}`
    };
    const response = await axios(config);
    return response.data;
  } catch (error: any) {
    console.log(error);
    alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
  }
  return undefined;
}
