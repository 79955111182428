import countries from "i18n-iso-countries";
import React, { FC } from "react";
import "./InvoiceAddressStyle.css";

type Props = {
  differentInvoiceAddressRequired: any;
  setDifferentInvoiceAddressRequired: any;
  invoiceAddress: any;
  setInvoiceAddress: any;
  isRequiredInvoiceProperties: IsRequiredInvoiceProperty;
};

const InvoiceAddress: FC<Props> = ({
  differentInvoiceAddressRequired,
  setDifferentInvoiceAddressRequired,
  invoiceAddress,
  setInvoiceAddress,
  isRequiredInvoiceProperties
}) => {
  const changeInvoiceAddress = (e: any, source: string) => {
    const invoiceAddressCopy = { ...invoiceAddress };
    invoiceAddressCopy[source] = e;
    setInvoiceAddress(invoiceAddressCopy);
  };

  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key
    };
  });
  countryArr.unshift({ label: "Choose a country", value: "" });

  const validateForm = (e: any) => {
    setRequiredInputValidationClass(e);
  };

  const setInputValidationClass = (e: Element, isValid: boolean) => {
    if (isValid) e.classList.remove("propertyForm-invalid");
    else e.classList.add("propertyForm-invalid");
  };

  const setRequiredInputValidationClass = (e: any) => {
    const _input = e.target;
    const _isValid = !(_input.required && _input.value.trim() === "");
    setInputValidationClass(_input, _isValid);
  };

  return (
    <>
      <div className="different-invoice-wrapper">
        <label className="diff-invoice-text" htmlFor="differentInvoiceAddress">
          Different Invoice Address
        </label>
        {differentInvoiceAddressRequired ? (
          <input
            type="checkbox"
            name="differentInvoiceAddress"
            value="true"
            onChange={() => setDifferentInvoiceAddressRequired(false)}
          />
        ) : (
          <input
            type="checkbox"
            name="differentInvoiceAddress"
            onChange={() => setDifferentInvoiceAddressRequired(true)}
          />
        )}

        {differentInvoiceAddressRequired && (
          <div className="invoice-address-inputs">
            <span className="inline">
              <label>
                <p>Company name{isRequiredInvoiceProperties.companyName && "*"}</p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredInvoiceProperties.companyName}
                  placeholder="Company name"
                  value={invoiceAddress.companyName}
                  onChange={e => changeInvoiceAddress(e.target.value, "companyName")}
                  onBlur={e => validateForm(e)}
                />
              </label>
              <label>
                <p>First name{isRequiredInvoiceProperties.firstName && "*"}</p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredInvoiceProperties.firstName}
                  placeholder="First name"
                  value={invoiceAddress.firstName}
                  onChange={e => changeInvoiceAddress(e.target.value, "firstName")}
                  onBlur={e => validateForm(e)}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>Last name{isRequiredInvoiceProperties.lastName && "*"}</p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredInvoiceProperties.lastName}
                  placeholder="Last name"
                  value={invoiceAddress.lastName}
                  onChange={e => changeInvoiceAddress(e.target.value, "lastName")}
                  onBlur={e => validateForm(e)}
                />
              </label>
              <label>
                <p>Tax number{isRequiredInvoiceProperties.taxNumber && "*"}</p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredInvoiceProperties.taxNumber}
                  placeholder="Tax number"
                  value={invoiceAddress.taxNumber}
                  onChange={e => changeInvoiceAddress(e.target.value, "taxNumber")}
                  onBlur={e => validateForm(e)}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>Address{isRequiredInvoiceProperties.address && "*"}</p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredInvoiceProperties.address}
                  placeholder="Address"
                  value={invoiceAddress.address}
                  onChange={e => changeInvoiceAddress(e.target.value, "address")}
                  onBlur={e => validateForm(e)}
                />
              </label>
              <label>
                <p>Postalcode{isRequiredInvoiceProperties.postalCode && "*"}</p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredInvoiceProperties.postalCode}
                  placeholder="Postalcode"
                  value={invoiceAddress.postalCode}
                  onChange={e => changeInvoiceAddress(e.target.value, "postalCode")}
                  onBlur={e => validateForm(e)}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>City{isRequiredInvoiceProperties.city && "*"}</p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredInvoiceProperties.city}
                  placeholder="City"
                  value={invoiceAddress.city}
                  onChange={e => changeInvoiceAddress(e.target.value, "city")}
                  onBlur={e => validateForm(e)}
                />
              </label>
              <label>
                <p>Country{isRequiredInvoiceProperties.country && "*"}</p>
                <select
                  className="dropDown"
                  value={invoiceAddress.country}
                  required={isRequiredInvoiceProperties.country}
                  onChange={e => changeInvoiceAddress(e.target.value, "country")}
                  onBlur={e => validateForm(e)}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
          </div>
        )}
      </div>
    </>
  );
};
export default InvoiceAddress;

export interface IsRequiredInvoiceProperty {
  companyName: boolean;
  firstName: boolean;
  lastName: boolean;
  taxNumber: boolean;
  address: boolean;
  postalCode: boolean;
  city: boolean;
  country: boolean;
}
