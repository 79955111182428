import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import AdminLogin from "./pages/AdminAuth/AdminLogin";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import JhErrorPage from "./pages/Error/JhErrorPage";
import GuestManagement from "./pages/GuestManagement/GuestManagement";
import Home from "./pages/Home/Home";
import OverviewBooking from "./pages/OverviewBooking/overviewBooking";
import RegistrationForm from "./pages/Registration/RegistrationPage";

const lngs: any = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
  es: { nativeName: "Spanish" },
  hu: { nativeName: "Hungarian" },
  ukr: { nativeName: "Ukranian" },
  ru: { nativeName: "Russian" }
};

const App = ({ en, de }: any, history: any) => {
  const { i18n } = useTranslation();

  return (
    <div className="body">
      <Router>
        <div className="header">
          <div className="language-selector-wrapper">
            <select value={i18n.resolvedLanguage} id="lngs" onChange={event => i18n.changeLanguage(event.target.value)}>
              {Object.keys(lngs).map(lng => (
                <option value={lng} key={lng}>
                  {lngs[lng].nativeName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/registrationform" element={<RegistrationForm />} />
            <Route path="/guestManagement" element={<GuestManagement />} />
            <Route path="/overview-booking" element={<OverviewBooking />} />
            <Route path="/error" element={<JhErrorPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
