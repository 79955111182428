import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

const configFileName = process.env.REACT_APP_FIREBASE_CONFIG_NAME;

/*if (!configFileName)
    console.error("No environment variable REACT_APP_FIREBASE_CONFIG_NAME was found");*/

// eslint-disable-next-line @typescript-eslint/no-var-requires
const firebaseConfig = require(`../env/${configFileName}.json`);

const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
if (window.location.hostname === "localhost") connectFirestoreEmulator(db, "localhost", 8083);
const auth = getAuth();

export { db, auth };
