import { Timestamp } from "firebase/firestore";

type Booking = {
  masterBookingId: string;
  createdAt: string;
  lastUpdatedAt: string;
  status: string;
  issuer: Issuer;
  differentInvoiceAddressRequired: boolean;
  registeredAt?: Timestamp;
};

type Issuer = {
  firstName: string;
  lastName: string;
  taxNumber: string;
  email: string;
  phoneNumber: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
};

type BookingItemDirect = {
  origin?: Origin;
  guests: Guest[];
  guestNumber: number;
  owner: string;
  propertyName: string;
  propertyId: string;
  itemBookingId: string;
  paid: number;
  currency: string;
  checkInTime: Timestamp;
  checkOutTime: Timestamp;
  status?: string;
  lastUpdatedAt: Timestamp;
};

type BookingItemTransmitted = {
  origin?: Origin;
  guests: Guest[];
  guestNumber: number;
  owner: string;
  propertyName: string;
  propertyId: string;
  propertyTechId: string;
  itemBookingId: string;
  paid: number;
  currency: string;
  checkInTime: TimestampTransmitted;
  checkOutTime: TimestampTransmitted;
  status?: string;
  lastUpdatedAt: TimestampTransmitted;
  show?: Show[];
};

type Show = {
  isLastNameRequired?: boolean;
  isGenderRequired?: boolean;
  isNationalityRequired?: boolean;
  isPlaceOfBirthRequired?: boolean;
  isAddressRequired?: boolean;
  isTypeOfIdRequired?: boolean;
  isIdRequired?: boolean;
  isEmailRequired?: boolean;
};

type TimestampTransmitted = { _seconds: number };

type Origin = {
  source: string;
  format: string;
  api: string;
  saved: string;
};
type Guest = {
  title?: string | null;
  firstName: string | null;
  lastName: string | null;
  address: string | null;
  postalCode: string | null;
  city: string | null;
  dateOfBirth: string | null;
  country: string | null;
  gender: "Male" | "Female" | "Other" | null;
  nationality: string | null;
  typeOfID: "idNumber" | "passport" | null;
  idNumber: string | null;
  placeOfBirth: string | null;
  email: string | null;
  isFamilyMember?: boolean | null;
};

type ServiceAccount = {
  type: string;
  project_id: string;
  private_key_id: string;
  private_key: string;
  client_email: string;
  client_id: string;
  auth_uri: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
};
type TenantCustomizationInfo = {
  logoUrl?: string;
  propertyDisplayName?: string;
  privacyPolicyUrl?: string;
  propertyRulesUrl?: string;
};
type Domain = {
  bookingManager?: string;
  alertFatalUrl?: string;
  backendBaseUrl?: string;
  alertErrorUrl?: string;
  originRefreshToken?: string;
  frontendBaseUrl?: string;
  originRegisteredString?: string;
  alertWarningUrl?: string;
  lastRefreshedTime?: { toDate(): Date };
  serviceAccount?: ServiceAccount;
  originAccessToken?: string;
};

type PropertyConfig = {
  owner: Owner;
  originId?: string;
  name?: string;
  address?: Address;
  propertyImages?: string[];
  access?: AccessConfig;
  checkInTime?: string;
  checkOutTime?: string;
  checkInOutMessageUrl?: string;
  bookCancelUpdateMessageUrl?: string;
  invoiceMessageUrl?: string;
  registrationMessageUrl?: string;
};

type Owner = {
  domain: Domain;
  name?: string;
  alertInfoUrl?: string;
  checkInOutMessageUrl?: string;
  bookCancelUpdateMessageUrl?: string;
  invoiceMessageUrl?: string;
  registrationMessageUrl?: string;
  entity?: string;
  address?: Address;
  accessMessageUrl?: string;
  hasCashRegister?: string | boolean;
  invoiceLanguage?: string;
  invoicingId?: string;
  sendEmailToGuest?: string | boolean;
  emailRecipients?: string;
  emailSubject?: string;
  emailBody?: string;
  invoiceCurrency?: string;
  bankAccount?: {
    [source: string]: {
      [currency: string]: {
        bank: string;
        bankAccountNumber: string;
      };
    };
  };
  tax?: {
    vat: {
      percentage: number;
      description: string | null;
      name: string;
    };
    touristicTax: {
      percentage: number;
      description: string | null;
    };
  };
};

type Address = {
  country: string;
  zipCode: string;
  city: string;
  street: string;
};

type AccessConfig = {
  accessType: LockType;
  accessCode: string | null;
  serialNumber: string | null;
  username: string | null;
  password: string | null;
  accessConfigInfo: AccessConfigInfo;
};

type AccessConfigInfo = {
  propertyEnabledControl: boolean;
  loxoneGatewayUrl: string;
};

enum LockType {
  NONE = "NONE",
  STATIC_CODE = "STATIC_CODE",
  TTLOCK = "TTLOCK",
  LOXONE = "LOXONE"
}

enum AccessCodeSeverity {
  NORMAL = "normal",
  WARNING = "warning",
  ERROR = "error"
}

type Access = {
  accessCode?: string | null;
  bookingId?: string;
  propertyId?: string;
  propertyName?: string;
  status?: StatusType;
  type?: LockType;
  info?: NoneAccessInfo | KeySafeAccessInfo | LoxoneAccessInfo;
  logs?: string[];
};

type NoneAccessInfo = {
  createdAt: TimestampTransmitted;
};

type KeySafeAccessInfo = {
  createdAt: TimestampTransmitted;
  changedAt: TimestampTransmitted | null;
  validFrom: TimestampTransmitted;
  validUntil: TimestampTransmitted;
};

type LoxoneAccessInfo = {
  createdAt?: TimestampTransmitted;
  changedAt?: TimestampTransmitted | null;
  validFrom?: TimestampTransmitted;
  validUntil?: TimestampTransmitted;
  userGroupUuid?: string;
  userName?: string;
  userId?: string;
  userUuid?: string;
  accessCode?: string;
  userPassword?: string;
  userInterfacePassword?: string;
};

enum StatusType {
  "INITIALIZING" = "INITIALIZING",
  "CREATED" = "CREATED",
  "CHECKED_OUT" = "CHECKED_OUT",
  "NORMAL" = "NORMAL",
  "EXTENSION" = "EXTENSION",
  "EXTENDED" = "EXTENDED",
  "CANCELLED" = "CANCELLED"
}

type GetBookingAccessResult = {
  severity: "normal" | "error" | "warning";
  access?: Access;
  error?: string;
};

type AccessCodeResponseDto = {
  validFrom: TimestampTransmitted;
  validUntil: TimestampTransmitted;
  accessCodes: AccessCode[];
};

interface AccessCode {
  severity?: AccessCodeSeverity;
  error?: string;
  info?: LoxoneAccessCode | TTLockAccessCode | NoneAccessCode | StaticCodeAccessCode;
  type?: LockType;
}

interface BaseAccessCode {
  name?: string;
}

interface TTLockAccessCode extends BaseAccessCode {
  accessCode?: string;
}

interface LoxoneAccessCode extends BaseAccessCode {
  accessCode?: string;
  userName?: string;
  userPassword?: string;
  propertyEnabledControl?: boolean;
  loxoneGatewayUrl?: string;
}

interface NoneAccessCode extends BaseAccessCode {}

interface StaticCodeAccessCode extends BaseAccessCode {
  accessCode?: string;
}

type GetLoginResult = {
  role: string;
  token: string;
};

type RegistrationPageData = {
  booking: Booking;
  bookingItems: BookingItemTransmitted[];
  backendBaseUrl: string | undefined;
  firestoreId: string;
};

type FsAccess = {
  id: string;
  data: Access;
  exists?: boolean;
};

type LoxoneCredentials = {
  url: string;
  username: string;
  password: string;
};

export {
  Booking,
  Guest,
  BookingItemDirect,
  BookingItemTransmitted,
  Issuer,
  Origin,
  PropertyConfig,
  TenantCustomizationInfo,
  Domain,
  Owner,
  Access,
  LockType,
  AccessConfigInfo,
  AccessCodeResponseDto,
  GetBookingAccessResult,
  RegistrationPageData,
  FsAccess,
  LoxoneCredentials,
  LoxoneAccessInfo,
  GetLoginResult,
  TimestampTransmitted,
  AccessCode,
  TTLockAccessCode,
  StaticCodeAccessCode,
  LoxoneAccessCode
};
