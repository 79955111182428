import { FileSyncOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookingWhole } from "../../../functions/src/common/types";
import book from "../../assets/book.png";
import { getAllProperties, getDomainConfig } from "../../utils/configuration";
import { PropertyConfig } from "../../utils/types";
import { isTokenForAdmin } from "../../utils/validation";
import "./GuestManagementStyle.css";

const handleNavigate = (navigate: any) => {
  if (!isTokenForAdmin()) {
    navigate("/admin-login");
  }
};

const GuestManagement = () => {
  const navigate = useNavigate();

  const parameters: any = {};
  window.location.search
    .slice(1)
    .split("&")
    .forEach((pair: string) => {
      const splitted = pair.split("=");
      parameters[decodeURIComponent(splitted[0])] = decodeURIComponent(splitted[1]);
    });
  const [rows, setRows]: any = useState([]);
  const [fromDate, setFromDate] = useState();
  const [untilDate, setUntilDate] = useState();
  const [registered, setRegistered] = useState();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [bookingNumber, setBookingNumber] = useState<number>();
  const [bookingId, setBookingId] = useState<string>();
  const [propertyIds, setPropertyIds] = useState<Map<string, string>>(new Map());
  const [selectedProperty, setSelectedProperty] = useState<string>();
  const [propertyIdGroups, setPropertyIdGroups] = useState<Map<string, string[]>>(new Map());
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchPropertyIds = async () => {
    const newPropertyIds = new Map(propertyIds);
    const newPropertyIdGroups = new Map(propertyIdGroups);
    const propertyConfigs = await getAllProperties();
    propertyConfigs.forEach((propertyConfig: PropertyConfig) => {
      newPropertyIds.set(propertyConfig.name!, propertyConfig.originId!);
      const street: string = propertyConfig.address!.street!;
      if (newPropertyIdGroups.has(street)) {
        const newStreet = newPropertyIdGroups.get(street);
        newStreet?.push(propertyConfig.originId!);
        newPropertyIdGroups.set(street, newStreet!);
      } else newPropertyIdGroups.set(street, [propertyConfig.originId!]);
    });
    setPropertyIds(newPropertyIds);
    setPropertyIdGroups(newPropertyIdGroups);
  };

  useEffect(() => {
    if (isTokenForAdmin()) {
      fetchPropertyIds();
    } else {
      handleNavigate(navigate);
    }
  }, []);

  useEffect(() => {
    console.log("isLoading has changed:", isLoading);
  }, [isLoading]); // Add a useEffect to log when isLoading changes

  const handleFetch = async () => {
    try {
      setTimeout(() => {
        setIsLoading(true);
      }, 0);

      setRows([]);
      setErrorMessage("");
      const bookings: BookingWhole[] = await callGetGuestManagementBookingsApi(
        fromDate,
        untilDate,
        bookingId,
        selectedProperty,
        selectedPropertyGroup
      );
      const domainConfig = await getDomainConfig();
      for (const booking of bookings) {
        for (const bookingItem of booking.items) {
          const checkIn = new Date((bookingItem.checkInTime as any)._seconds * 1000).toISOString().slice(0, 10);
          const checkOut = new Date((bookingItem.checkOutTime as any)._seconds * 1000).toISOString().slice(0, 10);
          let registeredAt;
          if (booking!.registeredAt) {
            registeredAt = new Date((booking.registeredAt! as any)._seconds * 1000).toISOString().slice(0, 10);
          } else registeredAt = "no";
          const propertyName = bookingItem.propertyName;
          const firstName = bookingItem.guests![0].firstName;
          const lastName = bookingItem.guests![0].lastName;
          const guestNumber = bookingItem.guestNumber;
          const backendBaseUrl = domainConfig.backendBaseUrl;
          const site = `${backendBaseUrl}redirectToRegPage?bookingId=${booking!.masterBookingId}`;
          const newLine = (
            <tr className="table-row" key={bookingItem.itemBookingId}>
              <td>
                <a href={site} target="_blank" rel="noopener noreferrer">
                  <img src={book} alt="Book icon" />
                </a>
              </td>
              <td>{bookingItem.itemBookingId}</td>
              <td>{guestNumber}</td>
              <td>{firstName}</td>
              <td>{lastName}</td>
              <td>{checkIn}</td>
              <td>{checkOut}</td>
              <td>{registeredAt}</td>
              <td>{propertyName}</td>
            </tr>
          );
          if (registered === "no" && !booking!.registeredAt) setRows((rows: any) => [...rows, newLine]);
          else if (registered === "yes" && booking!.registeredAt) setRows((rows: any) => [...rows, newLine]);
          else if (registered === "Def" || registered === undefined) setRows((rows: any) => [...rows, newLine]);
        }
      }
      setBookingNumber(bookings.length);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false); // Disable loading when done
    }
  };

  return (
    <div className="guestmanagement-wrapper">
      <div className="h1-div">
        <img
          className="logo"
          src="https://storage.googleapis.com/jobelhome-production.appspot.com//media/thumbnail.png"
          alt="NOTHING"
        />
        <h1 className="main-title">Guest Management page</h1>
      </div>

      <div style={{ margin: 30 }}>
        <label>
          <p>Get bookings based on Check in from:</p>
          <input
            className="inputField"
            type="date"
            name="From"
            value={fromDate}
            onChange={(e: any) => setFromDate(e.target.value)}
          />
        </label>
        <label>
          <p>Get bookings until:</p>

          <input
            className="inputField"
            type="date"
            name="Until"
            value={untilDate}
            onChange={(e: any) => setUntilDate(e.target.value)}
          />
        </label>
        <label>
          <p>Select based on registration</p>
          <select
            className="dropDown"
            name="registered"
            value={registered}
            onChange={(e: any) => setRegistered(e.target.value)}
          >
            <option value="Def">Choose an option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
        <label>
          <p>Select based on property</p>
          <select
            className="dropDown"
            name="selectProperty"
            value={selectedProperty}
            onChange={(e: any) => setSelectedProperty(e.target.value)}
          >
            <option value="Def">Choose a property</option>
            {[...propertyIds.keys()].map(propertyId => (
              <option value={propertyIds.get(propertyId)}>{propertyId}</option>
            ))}
          </select>
        </label>
        <label>
          <p>Select based on property group</p>
          <select
            className="dropDown"
            name="selectPropertyGroup"
            value={selectedPropertyGroup}
            onChange={(e: any) => setSelectedPropertyGroup(e.target.value)}
          >
            <option value="Def">Choose a property group</option>
            {[...propertyIdGroups.keys()].map(propertyIdGroup => (
              <option value={propertyIdGroups.get(propertyIdGroup)}>{propertyIdGroup}</option>
            ))}
          </select>
        </label>
        <label>
          <p>Search based on booking ID</p>
          <input
            className="dropDown"
            type="text"
            value={bookingId}
            onChange={(e: any) => setBookingId(e.target.value.trim())}
          ></input>
        </label>
        <div className="fetch-button-container">
          <button className="fetch-button" onClick={handleFetch}>
            <FileSyncOutlined className="button-icon" />
            Fetch bookings
          </button>
          {isLoading && (
            <div className="loading-overlay">
              <p className="loading-text">Loading...</p>
            </div>
          )}
        </div>
        <p className="overview-error">{errorMessage}</p>
        {bookingNumber && <p className="booking-number">Found {bookingNumber} matching bookings</p>}
      </div>
      <table>
        <thead>
          <tr>
            <th className="freeze-top">Firestore Id</th>
            <th className="freeze-top">Booking Id</th>
            <th className="freeze-top">Guest Number</th>
            <th className="freeze-top">Guest Firstname</th>
            <th className="freeze-top">Lastname</th>
            <th className="freeze-top">Check-in</th>
            <th className="freeze-top">Check-out</th>
            <th className="freeze-top">Registered</th>
            <th className="freeze-top">Property Name</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

async function callGetGuestManagementBookingsApi(
  fromDate: string | undefined,
  untilDate: string | undefined,
  bookingId: string | undefined,
  selectedProperty: string | undefined,
  selectedPropertyGroup: string | undefined
): Promise<BookingWhole[]> {
  const callGetGuestManagementBookings = async (): Promise<BookingWhole[]> => {
    const domainConfig = await getDomainConfig();
    let guestManagementData: BookingWhole[] = [];

    if (fromDate === undefined) fromDate = "undefined";
    if (untilDate === undefined) untilDate = "undefined";
    if (bookingId === undefined) bookingId = "undefined";
    if (selectedProperty === undefined) selectedProperty = "undefined";
    if (selectedPropertyGroup === undefined) selectedPropertyGroup = "undefined";

    try {
      let token = localStorage.getItem("token");
      if (token == null) token = "no-token-found";
      const config = {
        method: "get",
        url: `${domainConfig.backendBaseUrl}getGuestManagementBookings`,
        headers: { "Content-Type": "application/json", Authorization: token },
        params: {
          fromDate: fromDate,
          untilDate: untilDate,
          bookingId: bookingId,
          selectedProperty: selectedProperty,
          selectedPropertyGroup: selectedPropertyGroup
        }
      };
      const response = await axios(config);
      if (response.status === 200) {
        guestManagementData = response.data;
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    }

    return guestManagementData;
  };

  return await callGetGuestManagementBookings();
}

export default GuestManagement;
